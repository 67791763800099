import React from "react";
// Dependencies
import { NavLink } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
// Components
import SocialMedia from "../../components/SocialMedia";
import RunningText from "../../components/RunningText";

export default function Index() {
  const texts = [
    "5+ Years of Experience in Digital Product Development",
    "MBA Graduate with a Focus on Strategic Management",
    "10+ Successful Product Launches",
    "Proven expertise in Managing Digital Product",
    "Skilled in Agile & Cross-Functional Collaboration",
    "5+ Professional Certifications",
    "Based in Jakarta, Indonesia",
    "Open to Product & Project Management Roles | Frontend Engineer Opportunities",
  ];
  const imgHome =
    "https://images.unsplash.com/photo-1577084381314-cae9920e6871?q=80&w=1704&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <div className="home transition-opacity duration-500 w-full">
      <div className="w-screen bg-[#f8f9fa] mt-16">
        <div className="flex-col">
          <div
            className="h-[180px] p-4 banner flex justify-center"
            style={{
              backgroundImage:
                "linear-gradient(grey, grey), url(" + imgHome + ")",
            }}
          >
            <h2 className="leading-[6rem] mt-8">
              <span>He-</span>
              <span>llo.</span>
            </h2>
          </div>
          <div className="py-12 mt-10 text-[#2d3436] text-center tracking-wider">
            <h5 className="text-xl">I'm Rizky Ramadika</h5>
            <h6>
              Bridging Technical Expertise with Strategic Digital Product
              Development
            </h6>
            <div className="flex items-center justify-center gap-4 mt-3 sm:flex-col xs:flex-col sm:gap-2 xs:gap-2">
              {/* Check out more about me&nbsp; */}
              <NavLink
                to="/about"
                className="flex items-center gap-2 border bg-[#e74c3c] text-white px-4 py-1 rounded-xl hover:opacity-40 text-xs font-semibold"
              >
                Discover My Journey
                <FaUserCircle />
              </NavLink>
              <span className="text-xs">or</span>
              <div className="underline underline-offset-4 text-[#e74c3c] flex gap-1 items-center justify-center font-semibold text-xs">
                <a
                  href="resume/Resume_-_Rizky_Ramadika_Trisaputra.pdf"
                  className="hover:opacity-40 transition-colors duration-300 "
                  download
                >
                  View Resume
                </a>
                <FiArrowUpRight />
              </div>
            </div>
          </div>
          <RunningText texts={texts} />
        </div>
      </div>
      <div className="mt-8 px-4">
        <SocialMedia />
      </div>
    </div>
  );
}
