import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About me", href: "/about" },
  // { name: "Services", href: "/services" },
  { name: "My Projects", href: "/work" },
  // { name: "Blog", href: "/blog" },
  // { name: "Contact", href: "/contact" },
];

export default function Index() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white text-[#646464] mt-16 navbar">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-end p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 sm:hidden xs:hidden md:hidden">
          <NavLink to="/" className="flex lg:flex-1 items-center gap-2">
            <img
              alt=""
              src="https://res.cloudinary.com/dp5hwzmhm/image/upload/v1710386454/IMG-20221029-WA0001_d88ykf.jpg"
              className="w-8 h-8 rounded-full object-cover"
            />
            <div className="flex flex-col">
              <h2 className="capitalize">Rizky Ramadika Trisaputra</h2>
              <p className="uppercase text-[#447229] flex gap-1 items-center">
                <span className="w-1 h-1 bg-[#447229] rounded-full animate-pulse"></span>
                Open to work
              </p>
            </div>
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-end rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className="text-sm font-semibold leading-6 tracking-widest"
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 tracking-widest hover:bg-gray-50"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
