import React from "react";
import {
  AcademicCapIcon,
  BookOpenIcon,
  BookmarkSquareIcon,
} from "@heroicons/react/24/outline";
import { HiMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { FaLinkedin, FaMediumM } from "react-icons/fa";
import { AiFillInstagram, AiFillGithub } from "react-icons/ai";
import { FiArrowUpRight } from "react-icons/fi";
import {
  education,
  skillset,
  work_experiences,
  organization_experiences,
  contact_info,
  course_n_sertification,
} from "../../store/constants";

export default function index() {
  const {
    email,
    location,
    github_account,
    instagram_account,
    linkedin_account,
    medium_account,
  } = contact_info;
  const mailTo = `mailto:${email}`;

  return (
    <div className="about transition-opacity duration-500 w-full">
      <div className="h-full py-8 px-20 flex flex-col justify-center items-center gap-4 md:py-6 sm:px-8 xs:px-8 text-base sm:gap-2 xs:gap-2">
        <div className="flex flex-col justify-center items-center gap-4">
          <img
            src={
              "https://res.cloudinary.com/dp5hwzmhm/image/upload/v1710386454/IMG-20221029-WA0001_d88ykf.jpg"
            }
            alt="img-contact"
            className="object-cover h-24 w-96"
          ></img>
          <h1 className="text-black text-6xl font-bold uppercase tracking-tighter leading-none sm:text-center xs:text-center md:text-center sm:text-5xl xs:text-5xl">
            RIZKY RAMADIKA TRISAPUTRA
          </h1>
          <div className="flex items-center gap-1 underline underline-offset-4">
            <MdLocationOn />
            <a
              href={location}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              Jakarta, Indonesia
            </a>
          </div>
          <div className="flex items-center gap-4">
            <a
              href={linkedin_account}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              <FaLinkedin size={20} className="text-cyan-700" />
            </a>
            <a
              href={mailTo}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              <HiMail size={20} className="text-cyan-700" />
            </a>
            <a
              href={instagram_account}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              <AiFillInstagram size={20} className="text-cyan-700" />
            </a>
            <a
              href={medium_account}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              <FaMediumM size={20} className="text-cyan-700" />
            </a>
            <a
              href={github_account}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-40 transition duration-300"
            >
              <AiFillGithub size={20} className="text-cyan-700" />
            </a>
          </div>
        </div>
        <div>
          <div className="text-[#e74c3c] flex items-center gap-4 font-semibold py-4 sm:text-sm xs:text-sm">
            <a
              href="resume/Resume_-_Rizky_Ramadika_Trisaputra.pdf"
              className="hover:opacity-40 transition-colors duration-300 "
              download
            >
              View Resume &#8594;
            </a>
          </div>
        </div>
      </div>
      <div className="h-full px-16 flex flex-col gap-36 mt-8">
        {/* education */}
        <div>
          <div className="flex items-center justify-start gap-8 sm:flex-col xs:flex-col">
            <div className="w-3/12 mr-8 sm:w-full xs:w-full sm:mr-0 xs:mr-0">
              <h2 className="text-sm tracking-wider uppercase text-[#d63031]">
                Education
              </h2>
              <h4 className="text-xl italic font-semibold mt-2 md:text-sm sm:text-sm xs:text-sm">
                "Embrace each lesson as a stepping stone, for in the journey of
                learning, we discover the limitless possibilities that shape our
                character and define our path to success."
              </h4>
            </div>
            {education.map((item, i) => (
              <div
                key={i}
                className="border rounded-lg w-4/12 sm:w-full xs:w-full"
              >
                <img
                  src={item.thumbnail}
                  alt="university-img"
                  className="w-full h-52 object-cover rounded-t-lg"
                />
                <div className="flex-col p-4 text-[#2d3436]">
                  <h4 className="text-xl tracking-wider uppercase font-semibold">
                    {item.degree} Degree
                  </h4>
                  <h6 className="text-xs">{item.major}</h6>
                  <h6 className="text-xs italic mt-2">{item.place}</h6>
                  <h4 className="text-[#e74c3c] mt-4 font-light tracking-wider uppercase">
                    {item.year}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* skillset */}
        <div>
          <div className="flex items-center justify-center gap-10 sm:flex-col xs:flex-col">
            <div className="border rounded-md bg-[#e74c3c] leading-relaxed text-[#ffffff] p-4 pt-16 w-3/12 h-80 text-center md:w-4/12 sm:w-full xs:w-full">
              <span className="text-9xl font-semibold block">5+</span>
              <span className="block text-xl">Years</span>
              <span className="block text-xl">Working Experience</span>
            </div>
            <div className="flex gap-4 flex-wrap w-4/12 md:w-6/12 sm:w-full xs:w-full">
              {skillset.map((item, index) => (
                <div
                  key={index}
                  className="border rounded-3xl w-fit px-4 py-2 shadow"
                >
                  <h4 className="md:text-sm">{item.category}</h4>
                </div>
              ))}
            </div>
          </div>
          {/* work experience */}
          <div className="my-6">
            <div className="flex-col items-center justify-center">
              <div className="flex justify-center gap-4 md:flex-wrap sm:items-center xs:items-center sm:flex-col xs:flex-col">
                {work_experiences.map((item, index) => (
                  <div
                    key={index}
                    className="w-60 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-4"
                  >
                    <img
                      src={item.icon}
                      alt="company-icon"
                      className="w-full h-12 object-cover"
                    />
                    <div className="h-20 mt-6 md:h-32 sm:h-28 xs:h-32">
                      <h4 className="text-xs">{item.company}</h4>
                      <h6 className="font-semibold text-base leading-6">
                        {item.role}
                      </h6>
                    </div>
                    <div className="text-[#e74c3c] mt-4 font-semibold text-xs">
                      <h6>{item.time}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="italic text-sm text-center text-[#7f8c8d] md:text-xs sm:text-xs xs:text-xs">
              "Every role is a chapter in the story of our professional journey.
              With each experience, we gain not just skills, but a deeper
              understanding of our capabilities. From challenges emerged growth,
              and every project, a canvas for innovation."
            </h2>
          </div>
        </div>
        {/* course & sertification */}
        <div>
          <div className="flex items-center justify-center gap-4 py-4 md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse">
            <div className="flex flex-wrap items-center justify-center gap-6 w-10/12 md:w-full sm:w-full xs:w-full sm:flex-col xs:flex-col sm:gap-2 xs:gap-2">
              {course_n_sertification.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-center w-60 flex-col items-center text-center sm:h-auto xs:h-auto"
                >
                  <div className="flex justify-center">
                    {item.id === 1 || item.id === 8 ? (
                      <AcademicCapIcon className="w-12 rounded-full bg-[#d63031] text-white p-3" />
                    ) : item.id === 3 || item.id === 4 || item.id === 5 ? (
                      <BookOpenIcon className="w-12 rounded-full bg-[#d63031] text-white p-3" />
                    ) : (
                      <BookmarkSquareIcon className="w-12 rounded-full bg-[#d63031] text-white p-3" />
                    )}
                  </div>
                  <div className="my-2 text-wrap">
                    <h6 className="text-xs text-[#e74c3c]">{item.period}</h6>
                    <h6 className="text-xs">{item.institute}</h6>
                    <h6 className="font-semibold">{item.title}</h6>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-3/12 md:w-full sm:w-full xs:w-full">
              <div>
                <span className="text-sm sm:text-xs xs:text-xs">
                  Courses and Certifications
                </span>
                <span className="block font-semibold text-5xl text-[#d63031] leading-tight mt-2 sm:text-xl xs:text-xl">
                  Empowering Through Education
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* organization experience */}
        <div>
          <div className="flex-col items-start gap-8">
            <div className="w-6/12 md:w-8/12 sm:w-10/12 xs:w-10/12">
              <h2 className="uppercase tracking-wider text-2xl">
                <span className="font-semibold">
                  Organizational experiences
                </span>
                &nbsp;are the building blocks of leadership, resilience, and
                growth.
              </h2>
              <h5 className="text-sm font-light text-[#d63031]">
                #LeadershipJourney #ProfessionalDevelopment #TeamCollaboration
              </h5>
            </div>
            <div className="flex items-start mt-4 px-4 md:flex-col sm:flex-col xs:flex-col sm:mt-8 xs:mt-8">
              {organization_experiences.map((item, index) => (
                <div
                  className="relative bg-cover bg-center bg-no-repeat bg-blend-multiply h-60 sm:h-72 xs:h-80 w-full flex flex-col justify-center items-start gap-3 py-6 px-4 text-white"
                  style={{
                    backgroundImage: `linear-gradient(grey, grey), url(${item.bg_img})`,
                  }}
                  key={index}
                >
                  <div className="h-20">
                    <h6 className="text-xs uppercase tracking-wider font-light">
                      {item.period}
                    </h6>
                    <h4 className="text-xl font-semibold sm:text-base xs:text-base">
                      {item.role}
                    </h4>
                    <h6 className="text-sm">{item.organization}</h6>
                  </div>
                  <h6 className="text-xs font-light">{item.desc}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-col py-20 mb-12">
          <h1 className="text-center leading-6 tracking-wider text-sm">
            <span className="text-[#2d3436] flex justify-center sm:flex-col xs:flex-col xs:items-center sm:items-center gap-2">
              I'm always up for adventure and new opportunities. Feel free to
              say hello to me via
              <a
                href="mailto:ramadika.trisaputra@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="hover:opacity-40 transition duration-300 flex items-center gap-1 text-sm font-semibold"
              >
                ramadika.trisaputra@gmail.com <FiArrowUpRight size={20} />
              </a>
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
}
