import React from "react";

interface TextScrollerProps {
  texts: string[];
}

export default function Index(props: TextScrollerProps) {
  const { texts } = props;
  return (
    <div className="overflow-hidden whitespace-nowrap bg-[#cffa63] text-black ">
      <div className="top-0 animate-marquee whitespace-nowrap flex items-center">
        {texts.map((text, index) => (
          <React.Fragment key={index}>
            {index !== 0 && ( // Don't add the bullet before the first item
              <span className="text-red-500 mx-2 text-4xl">•</span> // Adjust `mx-4` to increase or decrease spacing
            )}
            <span className="inline-block text-sm mx-20 tracking-widest font-semibold">
              {text}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
